import React, { FC } from 'react';
import clsx from 'clsx';
import { IMenuTypes } from 'utils/models';
import useAuth from 'hooks/useAuth';

// components
import SideActions from './SideActions';

const SideBar: FC = () => {
  const { user } = useAuth();

  const links: IMenuTypes[] = [];

  if (
    user?.is_super_admin ||
    user?.user_type?.patient_access ||
    user?.user_type?.login_patient_access
  ) {
    links.push({
      label: 'Patients',
      to: '/patients'
    });
  }

  if (
    user?.is_super_admin ||
    user?.user_type?.doctor_access ||
    user?.user_type?.login_doctor_access
  ) {
    links.push({
      label: 'Doctors',
      actions: [
        {
          label: 'Doctor List',
          to: '/doctors'
        },
        {
          label: 'Doctor Types Management',
          to: '/doctor-types'
        },
        {
          label: 'Doctor Consultation Types Management',
          to: '/doctor-consultation-types'
        }
      ]
    });
  }

  if (
    user?.is_super_admin ||
    user?.user_type?.consultation_access ||
    user?.user_type?.reset_consultation_access ||
    user?.user_type?.generate_prescription_access ||
    user?.user_type?.reschedule_access ||
    user?.user_type?.did_not_attend_access ||
    user?.user_type?.cancel_access ||
    // @TODO
    user?.user_type?.consultation_logs_access
  ) {
    links.push({
      label: 'Consults',
      to: '/consultations'
    });
  }

  if (user?.is_super_admin || user?.user_type?.product_access) {
    links.push({
      label: 'Products',
      to: '/products'
    });
  }

  if (user?.is_super_admin || user?.user_type?.prescription_access) {
    links.push({
      label: 'Orders',
      to: '/orders'
    });
  }

  if (user?.is_super_admin || user?.user_type?.coupon_access) {
    links.push({
      label: 'Coupons',
      to: '/coupons'
    });
  }

  if (user?.is_super_admin) {
    links.push({
      label: 'User Management',
      actions: [
        {
          label: 'User Access Management',
          to: '/users'
        },
        {
          label: 'User Types Management',
          to: '/user-types'
        },
        {
          label: 'Payment Transactions',
          to: '/payment-transactions'
        }
      ]
    });
  }

  // links.push({
  //   label: 'Log out',
  //   setting: true,
  //   onClick: () => logout()
  // });

  return (
    <div className="hidden md:block bg-white h-full">
      <div className={clsx('bg-white h-full relative w-max')}>
        <div className="pt-5">
          <SideActions actions={links} />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
